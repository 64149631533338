<template>
  <div class="page-privacy">
    <div class="navbar"  > 
        <van-icon name="arrow-left" size=".5rem" @click="$router.back()"/>
        隐私协议 
        <i style="opacity: 0;">隐私</i>
    </div>
    <div class="content">
        <p>
      <span
        class="lark-record-clipboard"
      ></span>
    </p>
    <div
      data-page-id="JuwvdT4suoMrPFxcUK2cBN0andd"
      data-docx-has-block-data="false"
      id="true"
    >
      <div
       style="text-align: center;font-size: .4rem; font-weight: bold;margin-bottom: .2rem;"
        class="ace-line ace-line old-record-id-JuwvdT4suoMrPFxcUK2cBN0andd"
      >
        隐私协议
      </div>
      <div
       
        class="ace-line ace-line old-record-id-USYEdo66ioUMUQxIvaXcq8OjnDg"
      >
        慧言注重对保护您的个人隐私的保护。有时候我们需要某些信息才能为您提供您请求的服务，本隐私权声明解释了这些情况下的数据收集和使用情况。本隐私权声明适用于慧言网站的所有相关服务。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-UE2mdeoc2oEmAUxGaoicSFy2nQd"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-DKkEd4OYKoWi8axOWG3ct2srnYd"
      >
        您个人信息的搜集
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Dk6AdsWOgoOmUMxYZ8vcu35fnrc"
      >
        当我们需要能识别您身份的信息（个人信息）或者可以与您联系的信息时，我们会征求您的同意。通常，在您注册慧言在线服务时，我们会请求您提供这些信息。慧言搜集的信息通常仅限于您的姓名、性别、年龄、出生日期、身份证号、家庭住址、教育程度、公司情况、所属行业、兴趣爱好等。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-K4aodY4MmoyaI4xyGryc0E1vnQb"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-K2kkdCmOeosiaqxmAaicBkqfnNd"
      >
        控制您的个人信息
      </div>
      <div
       
        class="ace-line ace-line old-record-id-AoQAdGqeaosAyyx6zjmcc9DwnJs"
      >
        慧言会在法律要求或符合慧言网站的相关服务条款的情况下透露您的个人信息，或者有充分理由相信必须这样做才能：(a)
        满足法律的明文规定，或者符合慧言适用的法律程序；（b）符合慧言网站相关服务条款；(c)
        保护慧言及其系列 Web 站点的权利或财产，以及 (d)
        在紧急情况下保护慧言员工、慧言产品或服务的用户或大众的个人安全。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-UmM2dYaQYoq4osxOKQkcBsmPn0e"
      >
        慧言不会未经允许将这些信息与第三方共享，本声明已经列出的情况除外。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Eq4SdQwsmoOWgsx2fnrc9qdPngc"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-TW02dG2a6o4a6exEVpzc1GcTnOc"
      >
        您个人信息的安全
      </div>
      <div
       
        class="ace-line ace-line old-record-id-CY80dYmeEo8awgxMJrac48T1n58"
      >
        严格保护您的个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄漏。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-CmQWdmWAgokw2SxCCZTczyOenef"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-MU4AdcA4KoQCysxQxNecd2k4n5e"
      >
        Cookie 的使用
      </div>
      <div
       
        class="ace-line ace-line old-record-id-DCqMdOYS4o8OqkxCwrGcXFv2nNc"
      >
        慧言使用 Cookie 来帮助您实现您的联机体验的个性化。Cookie
        是由网页服务器存放在您的硬盘中的文本文件。Cookie
        不能用来运行程序或将病毒递送到您的计算机中。指定给您的 Cookie
        是唯一的，它只能由将 Cookie 发布给您的域中的 Web 服务器读取。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-MaiWdqe60oiIgux4xjacCnqBn2f"
      >
        Cookie
        的主要用途之一是提供一种节约时间的实用功能。例如，如果您将慧言的网页个性化，或在慧言网站上注册，Cookie
        会帮助慧言在您后续访问时调用您的特定信息。这样可以简化记录您的个人信息（例如礼品寄送地址、首选电子邮件地址等）的流程。当您返回到慧言时，慧言可以调出您以前所提供的信息，使您能够容易地使用您自定义的慧言功能。慧言允许那些在我们网页上发布广告的公司到用户电脑上设定或取用Cookie。其他公司将按其自订的隐私权政策而非本政策使用
        Cookie。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-H2Qcd6iygoqqQQxU5lrcLAQNnTh"
      >
        慧言在进行与其服务相关的工作时，会使用网络beacons进入我们的网站提取
        Cookie。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-XegmdMMOcom4CexO5n5cgG60nWw"
      >
        您可以接受或拒绝 Cookie。大多数 Web 浏览器会自动接受
        Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝
        Cookie。如果选择拒绝
        Cookie，那么您可能无法完全体验所访问的慧言服务或网站的互动功能。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Cqoydu4SOok6qyxGesmcbCiOnOg"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Fg6UdCYY8oU6mmxuCX9cvYoynWb"
      >
        Web Beacon 的使用
      </div>
      <div
       
        class="ace-line ace-line old-record-id-TGwgdu0W8ossC2xmyS8cfa6Unkc"
      >
        慧言网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络
        beacon"），它们可以帮助网站计算浏览网页的用户或访问某些
        cookie。慧言使用网络 beacons 的方法包括: 在慧言网站中使用
        慧言通过在其网站上使用网络 beacons，计算用户访问数量，并通过访问 cookie
        辨认注册用户。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-E0UqdWQKOoWksexqU5Hcz3O7nIg"
      >
        慧言通过得到的 cookies 信息，可以在慧言网站提供个性化服务。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-GEEmdIII0owayIxe8bacseA1nGg"
      >
        慧言网页还可能包含第三方合作伙伴所放置的 Web
        beacon，以便汇总统计信息以帮助确定联合推介和广告活动的有效性。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-VSWCdQUkCoAuUoxCCxucyO1jn2f"
      >
        在慧言网站外使用 慧言代表某些合作伙伴在合作伙伴的网站上使用网络 beacons
        进行调查研究。这些研究有助于了解和改善某些广告的效果。通过网络 beacons
        收集到的有关慧言用户的信息将汇总报告给我们的合作伙伴。这些汇总信息中包括人口统计和使用信息。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-DSGudWiQeoSEguxQnSgcIhr4nqg"
      >
        第三方广告网络的使用
        您在慧言网页上所看到的大多数联机横幅广告是由慧言展示的。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-KCS2da0KeoysKGxQ58zclaXenQh"
      >
        此外，慧言还允许其他公司，即所谓的第三方广告服务器或广告网络，在慧言网页上展示广告。其中一些广告网络会在您的计算机上放置一个永久性的
        Cookie。这样便可使广告网络在每次向您发送联机广告时识别出您的计算机。这样，广告网络会搜集到有关您或使用您计算机的其他人在何处看见他们的广告，以及确定哪些广告被点击。这些信息使得广告网络能够发布他们认为将是您最感兴趣的广告。慧言无权访问或控制可能由第三方广告服务器或广告网络放置的
        Cookie。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Bsy8dyCWgokAgKxUDdacHMHDntf"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-LqGOdkOUSoAkKuxGsLjcY50BnUc"
      >
        访问您的个人信息
      </div>
      <div
       
        class="ace-line ace-line old-record-id-UsAQdmIMAoacY2xy4Soc5sPanCe"
      >
        我们为您提供了多种途径以确保您的个人信息是准确及时的。如果您在慧言网站上注册，您可以随时查阅或编辑您提交给慧言网站的个人信息，您也可以随时删除您提交给慧言网站的个人信息。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-PiKudcwakoyCyWx4c5Icf6cin9d"
      >
        <br />
      </div>
      <div
       
        class="ace-line ace-line old-record-id-AAo8dGciuoOIY0xwwWycHRXDnob"
      >
        关于免责
      </div>
      <div
       
        class="ace-line ace-line old-record-id-KMUkdw6WWoqwYmxA9idcu7uDnBb"
      >
        就下列相关事宜的发生，慧言不承担任何法律责任：
      </div>
      <div
       
        class="ace-line ace-line old-record-id-RyEidSsq2oGAmYxUBcEcXPqrnwh"
      >
        ·慧言根据法律规定或相关政府的要求提供您的个人信息；
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Cm08dmii2o0GMexSiY6cHndqntc"
      >
        ·由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因慧言原因导致的个人信息的泄漏；
      </div>
      <div
       
        class="ace-line ace-line old-record-id-Xe0QdyiWqogsiaxqGQLcjjacndb"
      >
        ·任何第三方根据慧言各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；
      </div>
      <div
       
        class="ace-line ace-line old-record-id-UMcKdSMWYoGsuYx8zFnc87lcn1f"
      >
        ·任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；
      </div>
      <div
       
        class="ace-line ace-line old-record-id-OWKidWGIQoQ6QuxsLOxcG1gbntg"
      >
        ·因不可抗力导致的任何后果；
      </div>
      <br>
      <div
       
       class="ace-line ace-line old-record-id-KKyudIm6aowoCkxCafVch6v3n2e"
     >
     您知悉并理解，本应用程序下的所有输出内容为人工智能模型自动生成，数据源为公开互联网数据。程序回答生成的所有内容仅供您参考，不代表我们的态度或观点，不应视为互联网新闻信息，也不能作为专业性建议或意见。
您应对本平台服务中的内容应自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的合法性、正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对因前述风险而导致的任何损失或损害承担责任。
     </div>
     <br>
      <div
       
        class="ace-line ace-line old-record-id-KKyudIm6aowoCkxCafVch6v3n2e"
      >
        慧言在各服务条款及声明中列明的使用方式或免责情形。
      </div>
      <div
       
        class="ace-line ace-line old-record-id-IwumdE8Suoa448xw7yucwPQIn8d"
      >
        慧言会不时更新本隐私权声明。更新时，我们还会修改隐私权声明顶部的"最近更新"的日期。
      </div>
    </div>
    </div>
   
  </div>
</template>
<script>
export default {
  data(){
    return{
      wx:this.deviceEnvir()
    }
  },
  // mounted(){
  //   if(window.location.href.includes("feishu.cn")){
  //     alert(1)
  //   }else{
  //     alert(2)
  //   }
    
  // },
  methods:{
    deviceEnvir() {
	    let ua = navigator.userAgent.toLowerCase();
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if(/micromessenger/.test(ua)){
          return true
      }
	    // return /micromessenger/.test(ua) ? true : false;
	    return isIOS ? false : true;


    }
  }
}
</script>
<style scoped>
.navbar{
  position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:white;
    height: 1rem;
    padding: 0 .1rem;
    box-sizing: border-box;
}
.content{
    padding:1.3rem .3rem .3rem;
    box-sizing: border-box;
}
div{
    width: 100%;
    font-size: .3rem;
    line-height: 1.5;
}
</style>